body {
  --tagifyer-blue: #0060ff;
  --tagifyer-pink: #C01776;
  --tagifyer-white: #CCCCCC;
  --tagifyer-transparent: rgba(128,128,128,.1);
  font-family: sans-serif;
  font-size: 18px;
  color: #eee;
  background-color: black;

  /* Background pattern from Toptal Subtle Patterns */
  /* Unused background options
  background-image: url("./assets/backgrounds/noisy_net/noisy_net.png");
  background-image: url("./assets/backgrounds/binding_dark/binding_dark.png");
  background-image: url("./assets/backgrounds/papyrus-dark/papyrus-dark.png");
  */
  background-image: url("./assets/backgrounds/ep_naturalblack/ep_naturalblack.png");
}
hr {
  border-top: 2px solid var(--tagifyer-pink);
  margin-top: .75rem;
  margin-bottom: .75rem;
}
h1 {
  color: var(--tagifyer-pink);
  font-style: italic;
  font-weight: bold;
  padding-left: .75rem;
  font-size: 3rem;
}
h3 {
  color: var(--tagifyer-pink);
  font-weight: bold;
  padding-left: .75rem;
}
ul, ol {
  list-style: none;
  counter-reset: listCount;
}
li {
  counter-increment: listCount;
}
ol li::before {
  content: counter(listCount) ". ";
  color: var(--tagifyer-pink);
  font-weight: bold;
}
.popupList li::before {
  content: "• ";
  color: var(--tagifyer-pink);
  font-weight: bold;
}
.modal-title {
  color: var(--tagifyer-pink);
  font-weight: bold;
  padding-left: .75rem;
}
a {
  color: var(--tagifyer-blue);
  font-weight:bold;
}
a:hover {
  color: var(--tagifyer-white);
  font-weight: bold;
}
.pinkText {
  color: var(--tagifyer-pink);
}
.top {
  min-width: 1000px;
  max-width: 80vw;
  margin: auto;
  margin-top: 1%;
}
/*
 * Logged Out
 */
.introduction {
  margin: 2%;
}
/*
 * Header
 */
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.title {
  width: 200px;
  text-align: left;
  margin-right: 5%;
}
.menu {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
}
.menuItem {
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 1%;
  margin-top: 1%;
}
.tagButton {
  margin-left: 10px;
  margin-right: 10px;
}
/*
 * Tags
 */
.tags {
  display: grid;
  grid-row-gap: 10px;
}
.createTagRow {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-column-gap: 25px;
  justify-items: center;
  align-items: center;
  margin-left: 25px;
}
.tagUnderEdit {
  display: inline;
  color: var(--tagifyer-pink);
}
.deleteTag {
  justify-self: center;
}
.tagsLabel {
  margin-bottom: .25rem;
  margin-top: .25rem;
}
.tagList {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  margin-left: 25px;
}
.tagListButtons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 25px;
  margin-bottom: .25rem;
  margin-top: .25rem;
}
.tagFilter {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  margin-left: 25px;
}
.tagFilterButtons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 25px;
  margin-bottom: .25rem;
  margin-top: .25rem;
}
/*
 * Multi-Song Operations
 */
.applyAllContent {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
}
/*
 * Song List Headers
 */
.table th {
  color: var(--tagifyer-pink);
  outline: none;
  border: none;
  padding-top: 0;
}
.songsHeader {
  display: flex;
  flex-direction: row;
  min-width: 15rem;
  height: 6rem;
}
.songsTitle {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  flex-grow: 1;
}
.numberOfSongs {
  color: var(--tagifyer-white);
  align-self: center;
  flex-grow: 3;
  padding-left: 5rem;
}
.artistHeader {
  min-width: 15rem;
  height: 6rem;
  display: flex;
  flex-direction: column;
}
.affinityHeader {
  min-width: 13rem;
  height: 6rem;
  display: flex;
  flex-direction: column;
}
.ratingHeader {
  height: 6rem;
  display: flex;
  flex-direction: column;
}
.titleHeader {
  min-width: 14rem;
  height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.deleteAllButton {
  height: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/*
 * Song List Body
 */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--tagifyer-transparent);
}
tr {
  color: var(--tagifyer-white);
}
.songLink {
  color: var(--tagifyer-white);
}
.affinityButton {
  font-weight: bold;
  width: 40px;
}
.ratingButton {
  font-weight: bold;
  width: 40px;
}
.noData {
  text-align: center;
  font-size: 2rem;
  color: var(--tagifyer-white);
}
/*
 * Song List Pagination
 */
.react-bootstrap-table-pagination-total {
  padding-left: 1rem;
  color: var(--tagifyer-white);
}
/*
 * Footer
 */
.footer {
  margin-top: 1%;
  margin-bottom: 1%;
  text-align: center;
}
.spotifyLogo {
  height: 25px;
  width: 25px;
  margin-right: 8px;
}
/*
 * Buttons
 */
.form-control {
  height: 40px;
  background: var(--tagifyer-transparent);
}
.input-group-text {
  color: var(--tagifyer-white);
  background-color: var(--tagifyer-transparent);
  border-color: var(--tagifyer-white);
  box-shadow: none;
}
.btn {
  font-weight: bold;
  border-style: solid;
  border-width: 2px;
  border-radius: 1px;
  transition: none;
}

.btn-tagifyer-menu,
.btn-tagifyer-menu:hover,
.btn-tagifyer-menu:active,
.btn-tagifyer-menu:focus,
.btn-tagifyer-menu.active {
  height: 40px;
  color: var(--tagifyer-white);
  background: var(--tagifyer-pink);
  background-color: var(--tagifyer-pink);
  border-color: var(--tagifyer-pink);
  box-shadow: none;
}

.btn-tagifyer-blue,
.btn-tagifyer-blue:hover,
.btn-tagifyer-blue:active,
.btn-tagifyer-blue:focus,
.btn-tagifyer-blue.active {
  color: var(--tagifyer-white);
  background: var(--tagifyer-blue);
  background-color: var(--tagifyer-blue);
  border-color: var(--tagifyer-blue);
  box-shadow: none;
}

.btn-outline-tagifyer-blue,
.btn-outline-tagifyer-blue:hover,
.btn-outline-tagifyer-blue:active,
.btn-outline-tagifyer-blue:focus,
.btn-outline-tagifyer-blue.active {
  color: var(--tagifyer-white);
  background: var(--tagifyer-blue);
  background-color: transparent;
  border-color: var(--tagifyer-blue);
  box-shadow: none;
}

.btn-tagifyer-white,
.btn-tagifyer-white:hover,
.btn-tagifyer-white:active,
.btn-tagifyer-white:focus,
.btn-tagifyer-white.active {
  color: black;
  background: var(--tagifyer-white);
  background-color: var(--tagifyer-white);
  border-color: var(--tagifyer-white);
  box-shadow: none;
}

.btn-outline-tagifyer-white,
.btn-outline-tagifyer-white:hover,
.btn-outline-tagifyer-white:active,
.btn-outline-tagifyer-white:focus,
.btn-outline-tagifyer-white.active {
  color: var(--tagifyer-white);
  background: var(--tagifyer-white);
  background-color: transparent;
  border-color: var(--tagifyer-white);
  box-shadow: none;
}

.btn-tagifyer-pink,
.btn-tagifyer-pink:hover,
.btn-tagifyer-pink:active,
.btn-tagifyer-pink:focus,
.btn-tagifyer-pink.active {
  color: var(--tagifyer-white);
  background: var(--tagifyer-pink);
  background-color: var(--tagifyer-pink);
  border-color: var(--tagifyer-pink);
  box-shadow: none;
}

.btn-outline-tagifyer-pink,
.btn-outline-tagifyer-pink:hover,
.btn-outline-tagifyer-pink:active,
.btn-outline-tagifyer-pink:focus,
.btn-outline-tagifyer-pink.active {
  color: var(--tagifyer-white);
  background: var(--tagifyer-pink);
  background-color: transparent;
  border-color: var(--tagifyer-pink);
  box-shadow: none;
}

/*
 * Popups
 */
.modal-content {
  background-color: black;
  outline: solid;
  outline-width: 2px;
  outline-color: white;
}
.modal-header {
  color: var(--tagifyer-pink);
  font-weight: bold;
}
.modal-footer {
  border-top: none;
  justify-content: center;
}
/* Removes the unused X from the modal header */
.close {
  display: none;
}
